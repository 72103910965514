.describe-loading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.describe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.describe-prompt {
  margin-top: 40px;
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.describe-banned-words {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  /* margin-bottom: 10px; */
  padding: 10px;
  text-align: center;
}

.describe-prompt-word {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.describe-time-left {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.describe-over {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-top: 40px;
  text-align: center
}