.app-header {
  text-align: center;
}

.leave-room-button-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leave-room-button {
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}