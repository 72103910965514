.all-chains-view {
  
}

.chain-button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
}

.individual-chain-view {
  padding: 20px;
}

.results-prompt-word {
  font-size: 20pt;
  font-weight: bold;
  margin-bottom: 10px;
}

.results-prompt-word-author {
  color: #666;
  margin-bottom: 8px;
}

.results-player-description-author {
  padding-left: 40px;
  margin-bottom: 8px;
  color: #666;
}

.results-player-description {
  font-size: 14pt;
  margin-bottom: 20px;
  padding-left: 40px;
}

.results-rewrite-prompt {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 20px;
}

.results-rewritten-description {
  font-size: 14pt;
  margin-bottom: 10px;
  padding-left: 40px;
  white-space: pre-wrap;
}

.chain-slide-buttons {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.chain-left-button, .chain-right-button {
  width: 50%;
  font-size: 16pt;
  margin: 2px;
  padding: 10px 20px;
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}