.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.lobby-qr-code {
  padding: 20px;
  max-width: 200px;
}

.lobby button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.leave-room-button {
  margin-top: 10px;
}

.lobby a {
  color: black;
  font-size: 14pt;
}

.lobby-player-list {
  width: 60%;
}

.lobby-player-list h2 {
  text-align: center; 
}

.lobby-player-list ul {
  /* list-style-type: none; */
  /* padding: 0; */
  margin-bottom: 40px;
}

.lobby-room-settings h2 {
  text-align: center; 
}

.lobby-room-setting {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.lobby-room-setting-value {
  margin-left: 10px;
}

button.lobby-room-setting-button {
  margin-right: 10px;
}

.lobby-player-list li {
  margin: 10px 0;
}

.lobby-player-name-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.lobby-player-name-entry input[type=text] {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  margin-right: 20px;
  padding: 10px;
  border: 1px solid #bbb;
  border-radius: 4px;
}

.lobby-player-name-entry input[type=submit] {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}