.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  text-align: center;
  margin-top: 10px;
}

.entry button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  border: none
}