.guess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 0 20px;
}

.guess-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.guess-loading-explanation {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.guess-loading-rewrite-prompt {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.guess-explanation {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.guess-rewrite-prompt {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.guess-rewritten-description {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.guess form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.guess-input {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 4px;
}

.guess-submit {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}